import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class FooterView extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    render() {
      return (
        <footer className="pt-16 pb-16 bg-secondary">
          <div className="container mx-auto text-center">
            <small className="uppercase font-bold">Copyright ©2022 | Sharma Design House, LLC. | All rights reserved.</small>
          </div>
        </footer>
      );
    }
}

FooterView.propTypes = {
    exampleVariable: PropTypes.string
};
