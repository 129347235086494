import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class SectionView extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    render() {
      const { heading , description , children , bg } = this.props;

      return (
        <section className={`pt-16 pb-16 ${bg ?? "bg-light"}`}>
          <div className="container mx-auto text-center font-semibold">
              {
                  heading ? <h2 className="text-4xl mx-auto pb-2">{heading ?? null}</h2> : null
              }

              {
                  description ? <p className="max-w-4xl mx-auto pb-8">{description}</p> : null
              }
            {children}
          </div>
        </section>
      );
    }
}

SectionView.propTypes = {
    heading: PropTypes.string,
    description: PropTypes.element
};
