import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ModalImage from "react-modal-image";
import logo from "./assets/portfolio/agency.png"

export default class ImageView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            img: "" ,
            currentImg: 0,
            viewerIsOpen: false
        };
        this.renderTextExample = this.renderTextExample.bind(this);
    }

    setImage(img){
        this.setState({
            ...this.state ,
            img: [
                {
                    src: img ,
                    width: 4,
                    height: 3
                }
            ]
        })
    }

    openLightbox(event, { photo, index }){
        this.setState({
            ...this.state ,
            currentImg: index ,
            viewerIsOpen: true
        });
    }

    closeLightbox(){
        this.setState({
            ...this.state ,
            currentImg: 0 ,
            viewerIsOpen: false
        });
    }

    async componentDidMount() {
        const {src} = this.props;
        const img = await import(`.${src}`);
        this.setImage(img ?? "https://via.placeholder.com/150");
    }

    render() {
        return this.renderTextExample();
    }

    renderTextExample() {
        const { src } = this.props;
        const { viewerIsOpen , currentImage , img } = this.state;
        return (
            <div className="aspect-square relative overflow-clip rounded-2xl border border-dark/10 shadow">
                <ModalImage
                    small={logo}
                    large={logo}
                    hideDownload={true}
                />
            </div>
        );
    }
}

ImageView.propTypes = {
    exampleVariable: PropTypes.string
};
