import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class InputView extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    render() {
        const { label , type } = this.props;
        return (
          <div className="form-group">
            <label className="form-label">{label}</label>
            <input type={type} className="border-dark/20"/>
          </div>
        );
    }
}

InputView.propTypes = {
    label: PropTypes.string.isRequired ,
    type: PropTypes.string.isRequired
};
