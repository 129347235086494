import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class NavView extends Component {
    constructor(props) {
        super(props);

        this.renderText = this.renderText.bind(this);
    }

    componentDidMount() {}

    render() {
        return <nav className="container mx-auto">{this.renderText()}</nav>;
    }

    renderText() {
        return (
          <ul>
            <li>
              <a href="#">
                <h1 className="text-3xl"><span className="text-2xl">try</span>SDH<span className="text-2xl">.com</span></h1>
              </a>
            </li>
            <li>
              <a href="#">
                solution
              </a>
            </li>
            <li>
              <a href="#">
                features
              </a>
            </li>
            <li>
              <a href="#">
                pricing
              </a>
            </li>
            <li>
              <a href="#">
                contact
              </a>
            </li>
            <li>
              <a href="#" className="btn btn-primary">Get Started</a>
              <a href="tel:18887816811" className="btn btn-light">
                  1-888-781-6811
              </a>
            </li>
          </ul>
        );
    }
}

NavView.propTypes = {
    exampleVariable: PropTypes.string
};
