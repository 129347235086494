import React, {Component, createRef} from 'react';
import PropTypes from 'prop-types';

export default class PricingCardv2View extends Component {
    constructor(props) {
        super(props);
        this.renderTextExample = this.renderTextExample.bind(this);
    }


    render() {
        return this.renderTextExample();
    }

    renderTextExample() {
        const { title , description , features , duration , price , featured } = this.props;
        return (
            <div className={`p-card ${featured ? "featured" : ""}`} ref={this.cardRef}>
                <div className="p-card-wrapper">
                    <div className="header" key={"header"}>
                        <h3 className="title">{title}</h3>
                        <p className="description"><small><i>{description}</i></small></p>
                    </div>
                    <div className="body" key={"info"}>
                        <ul className="capitalized">
                            {
                                features.map((feature , index ) => {
                                    return <li key={index}>
                                        <div className="relative flex basis-full self-center">
                                            <span className="icon" />
                                            {feature}
                                        </div>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                    <div className="footer-wrapper">
                        <div className="footer">
                            <h4 className="price pb-7">${price}<span className="text-base">{duration}</span></h4>
                            <a className="btn btn-primary">Buy {title}</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

PricingCardv2View.propTypes = {
    exampleVariable: PropTypes.string
};
