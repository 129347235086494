import React from 'react';
import NavView from "./components/nav/nav.view";
import HeroView from "./components/hero/hero.view";
import SectionView from "./components/section/section.view";
import FooterView from "./components/footer/footer.view";
import PricingCardv2View from "./components/card/pricing-cardv2/pricing-cardv2.view";
import Icon from './assets/icon/Vector.png'
import InputView from "./components/form/input/input.view";
import TextareaView from "./components/form/textarea/textarea.view";
import ImageView from "./components/carousel/image/image.view";

export default function App() {
    return (
        <>
            <NavView/>
            <HeroView/>
            <SectionView
                key={'s1'}
                heading="Websites for all Industries, yours included."
                description={
                    <>
                        Layout samples to get the creative juices flowing.
                    </>
                }
            >
                <div
                    className="grid grid-cols-2 md:grid-cols-4 gap-10">
                    <ImageView src={"/assets/portfolio/agency.png"}/>
                    <ImageView src={"/assets/portfolio/auto-glass-repair.png"}/>
                    <ImageView src={"/assets/portfolio/garden-center.png"}/>
                    <ImageView src={"/assets/portfolio/real-estate.png"}/>
                    <ImageView src={"/assets/portfolio/rentals.png"}/>
                    <ImageView src={"/assets/portfolio/restaurant-ecommerce.png"}/>
                    <ImageView src={"/assets/portfolio/retail-ecommerce.png"}/>
                    <div className="aspect-square">
                        <div className="flex flex-col justify-center h-full">
                            <h3 className="text-2xl pb-4">Need a custom layout?</h3>
                            <button className="btn btn-primary font-semibold w-1/2 self-center">
                                Let’s chat!
                            </button>
                        </div>
                    </div>
                </div>
            </SectionView>
            <SectionView
                key={'s2'}
                heading="Choose a Package that suits your needs."
                bg="bg-secondary"
            >
                <div className="flex text-light justify-center text-sm py-4">
                    <div className="p-4 bg-primary odd:rounded-l-xl basis-1/3 md:basis-1/4 lg:basis-1/6">
                        Subscription Plans
                    </div>
                    <div className="p-4 bg-dark/10 even:rounded-r-xl basis-1/3 md:basis-1/4 lg:basis-1/6">
                        Upfront Plans
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-4 pt-8">
                    <PricingCardv2View
                        key={"p1"}
                        title="Essential"
                        description="This custom web design package is best suited for small or startup businesses."
                        duration=" / per month"
                        features={[
                            "Beautiful Custom Design\n",
                            "Up to 10 Pages",
                            "Professionally Written Text",
                            "Basic On-Site SEO",
                            "Mobile Responsive",
                            "Free Hosting & SSL",
                            "Free Email & Domain",
                            "Customized Contact Form",
                            "One Time Set Up Fee: $500"
                        ]}
                        price={200}
                    />
                    <PricingCardv2View
                        key={"p2"}
                        title="Business"
                        description="This custom web design package is built for the established business."
                        duration=" / per month"
                        features={[
                            "Beautiful Custom Design",
                            "Up to 20 Pages",
                            "Professionally Written Text",
                            "Advanced On-Site SEO",
                            "Mobile Responsive",
                            "Free Hosting & SSL",
                            "Free Email & Domain",
                            "Custom Form for Requests",
                            "Schema & Structured Data",
                            "Google & Facebook Review Display",
                            "One Time Set Up Fee: $500"
                        ]}
                        featured={true}
                        price={300}
                    />
                    <PricingCardv2View
                        key={"p3"}
                        title="Business+"
                        description="This custom package is designed for businesses that need a little extra."
                        duration=" / per month"
                        features={[
                            "Beautiful Custom Design",
                            "Up to 32 Pages",
                            "Professionally Written Text",
                            "Advanced On-Site SEO",
                            "Mobile Responsive",
                            "Free Hosting & SSL",
                            "Free Email & Domain",
                            "Custom Form for Requests",
                            "Schema & Structured Data",
                            "Google & Facebook Review Display",
                            "One Time Set Up Fee: $500"
                        ]}
                        price={400}
                    />
                    <PricingCardv2View
                        key={"p4"}
                        title="Ecommerce"
                        description="This web design package is perfect for businesses that sell online."
                        duration=" / per month"
                        features={[
                            "Beautiful Custom Design",
                            "Up to 50 Products Installed",
                            "Coupon Codes & Gift Cards",
                            "iOS or Android App POS",
                            "Abandoned Cart Recovery",
                            "Payment Processor",
                            "Free Email & Domain",
                            "Customized Contact Form",
                            "Printable Shipping Labels",
                            "Discount on Shipping Costs",
                            "One-Time Set Up Fee: $995"
                        ]}
                        price={500}
                    />
                </div>
            </SectionView>
            <SectionView>
                <div className="container mx-auto">
                    <div className="p-4">
                        <div className="row row-cols-2 flex-row-reverse">
                            <div className="col-12 lg:col-6 p-8 bg-light border border-dark/5 rounded-lg shadow">
                                <h2 className="text-4xl text-left pb-4 max-w-sm">Send us a Message 🚀</h2>
                                <form>
                                    <div className="row row-cols-2">
                                        <div className="col-12 sm:col-6">
                                            <InputView
                                                label={"name"}
                                                type={"text"}
                                            />
                                            <InputView
                                                label={"email"}
                                                type={"email"}
                                            />
                                        </div>
                                        <div className="col-12 sm:col-6">
                                            <InputView
                                                label={"phone"}
                                                type={"tel"}
                                            />
                                            <InputView
                                                label={"Business Name"}
                                                type={"text"}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <TextareaView
                                                className="h-48"
                                                label={"Tell us more about your project"}/>
                                        </div>
                                        <div className="col-12 py-4">
                                            <button className="btn btn-primary font-semibold float-left">
                                                Send Message
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-12 lg:col-6 self-center">
                                <div className="max-w-md text-left mx-auto">
                                    <h4 className="text-5xl pb-8">Let’s chat.<br/>
                                        Tell us about your
                                        project.</h4>
                                    <h5 className="pb-8">Let’s create something together.</h5>
                                    <div className="flex">
                                        <div className="border shadow flex p-4 rounded-lg">
                                            <div className="border self-center p-2 m-1 rounded shadow-sm">
                                                <img src={Icon} alt={Icon} className="aspect-square"/>
                                            </div>
                                            <div className="pl-2">
                                                <h5 className="font-normal">Email us at</h5>
                                                <a href="#">info@sharmadesignhouse.com</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </SectionView>
            <FooterView/>
        </>
    );
}
