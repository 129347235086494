import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputView from "../form/input/input.view";
import TextareaView from "../form/textarea/textarea.view";

export default class HeroView extends Component {
    constructor(props) {
        super(props);

        this.renderTextExample = this.renderText.bind(this);
    }

    componentDidMount() {}

    render() {
        return <section className="text-center py-24  head-background">{this.renderText()}</section>;
    }

    renderText() {
        return (
            <div className="container mx-auto">
                <div className="row row-cols-2">
                    <div className="col-12 lg:col-6">
                        <h1 className="text-5xl pt-4 pb-12 font-semibold max-w-lg mx-auto">Let’s start building your better website today.</h1>
                        <form>
                            <div className="row row-cols-2">
                                <div className="col-12 sm:col-6">
                                    <InputView
                                        label={"First Name"}
                                        type={"text"}
                                    />
                                    <InputView
                                        label={"phone"}
                                        type={"tel"}
                                    />
                                </div>
                                <div className="col-12 sm:col-6">
                                    <InputView
                                        label={"Last Name"}
                                        type={"text"}
                                    />
                                    <InputView
                                        label={"Business Name"}
                                        type={"text"}
                                    />
                                </div>
                                <div className="col-12 pt-4">
                                    <button className="btn btn-primary font-semibold">
                                        I’m Ready!
                                    </button>
                                </div>
                                <div className="col-12 pt-4">
                                    <small className="text-dark/30">By entering your email address, you agree to receive emails from  Sharma Design House LLC in accordance with our Privacy Policy. You may unsubscribe from these communications at any time.</small>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-12 lg:col-6 self-center">
                        <img src="https://via.placeholder.com/540x333" alt="https://via.placeholder.com/540x333" className="head-banner mx-auto my-auto"/>
                    </div>
                </div>
            </div>
        );
    }
}

HeroView.propTypes = {
    exampleVariable: PropTypes.string
};
