import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class TextareaView extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    render() {
        const { label , className } = this.props;
        return (
          <div className="form-group">
            <label className="form-label">{label}</label>
            <textarea className={`border-dark/20 ${className ?? ""}`} />
          </div>
        );
    }
}

TextareaView.propTypes = {
  label: PropTypes.string.isRequired
};
